import { createRouter, createWebHistory } from 'vue-router'

const routesP = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/lixelGoPage.vue')
  },
  {
    path: '/humuStudioPage',
    name: 'humuStudioPage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/humuStudioPage.vue')
  }
];

// const routesM = [
//   {
//     path: '/',
//     name: 'phone',
//     component: () => import(/* webpackChunkName: "about" */ '../views/lixelGoPagePhone.vue')
//   },
//   {
//     path: '/humuStudioPage',
//     name: 'humuStudioPage',
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     component: () => import(/* webpackChunkName: "about" */ '../views/humuStudioPagePhone.vue')
//   }
// ];

let routes = routesP;
// const flag = navigator.userAgent.match(
//   /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i,
// );


// if (flag === null) {
//   routes = routesP;
// } else {
//   // 手机端
//   routes = routesM;
// }
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
